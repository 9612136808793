import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'source' ]

  connect() {
    this.initializeSelect2();

    // Fix select2 form inputs duplication
    // https://github.com/pascallaliberte/stimulus-turbolinks-select2
    document.addEventListener('turbolinks:before-cache', () => {
      this.destroySelect2();
    }, { once: true });
  }

  initializeSelect2() {
    $(this.sourceTarget).select2({
      theme: 'bootstrap',
      placeholder: 'None',
      allowClear: true
    });
  }

  destroySelect2() {
    $(this.sourceTarget).select2('destroy');
  }
}