import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "input"];

  connect() {
    if (this.inputTarget instanceof HTMLButtonElement || this.inputTarget instanceof HTMLAnchorElement) {
      this.inputTarget.addEventListener('click', () => {
        if (this.inputTarget.classList.contains('toggled-on'))
          this.inputTarget.classList.remove('toggled-on');
        else
          this.inputTarget.classList.add('toggled-on');

        this.toggle();
      });
    } else {
      this.inputTarget.addEventListener('change', () => this.toggle());
    }

    this.toggle();
  }

  update(element, checked) {
    let enableClass = this.data.get("class");
    if (checked) {
      element.classList.add(enableClass);
    } else {
      element.classList.remove(enableClass);
    }
  }

  toggle() {
    let type = this.data.get('type')

    if (type === "select") {
      let displayControl = null
      for (let index = 0; index < this.inputTarget.children.length; index++) {
        if(this.inputTarget.children.item(index).selected){
          displayControl = this.inputTarget.children.item(index).value
          break;
        }
      }

      this.contentTargets.forEach(element => {
        let checked = element.dataset["toggleSelected"] === displayControl;
        this.update(element, checked);
      });
    } else if (type === "button") {
      let checked = this.inputTarget.classList.contains('toggled-on');
      this.contentTargets.forEach(element => {
        this.update(element, checked);
      });
    } else {
      let checked = this.inputTarget.checked;
      this.contentTargets.forEach(element => {
        this.update(element, checked);
      });
    }
  }
}