/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../javascript/platform.js'
import '../javascript/user_guest.js'

import 'jquery'
import 'jquery-ui'
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ujs'
import 'bootstrap'
import 'bootstrap-datepicker'
import 'bootstrap-datetime-picker'
import 'chartkick'
import 'chart.js'
import 'select2'
import Cropper from 'cropper'
import Turbolinks from 'turbolinks'

document.addEventListener('turbolinks:before-cache', function () {
  // removing the select2 from all selects
  $("select").select2('destroy');
});

Turbolinks.start();
window.$ = $;

console.log('Webpacker Active')

import "controllers"
