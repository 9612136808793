
import moment from 'moment';
!function() {

  function initialize($root) {
    if (!$root)
      $root = $(document);

    $root.find('[data-toggle="tooltip"]').tooltip();

    $root.find('a.add_fields')
      .data("association-insertion-method", 'before')
      .data("association-insertion-node", 'this')
    ;

    $root.find('.switch').each(function() {
      var $switch = $(this);
      var $subject = $switch.find('.subject');
      $subject.change(function() {
        var conditionalValue = $(this).val();
        $switch.find('.case').hide();
        $switch.find('.case[data-value=\''+conditionalValue+'\']').show();
        $switch.find('.case[data-value^=\'!\']').not('[data-value=\'!'+conditionalValue+'\']').show();

      }).change();
    })

    $root.find('.sortable').sortable({
      items: '> .sort-item',
      handle: '.handle'
    });

    $(document).find('.sortable').sortable('refresh');
  }

  $(document).on('click', '.radio-choice-options', function() {
    var id = $(this).attr('data-for');
    var radio = document.getElementById(id);

    if (!radio)
      return;

    radio.checked = true;
  })

  $(document).on('turbolinks:load', function() {
    initialize($('main'));
  });

  $(document).on('cocoon:after-insert', function($ev, $query) {
    $('.order-id-container').each(function() {
      var value = $(this).find('input.master').val();

      if (!value) {
        value = uuid();
      }

      $(this).find('input').attr('value', value);
      $(this).find('input').val(value);

    });

    initialize($query);
  })

  function uuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  $(document).on('click', '.add-instance', function() {
    var type = $(this).data('type');
    var newItem = $('.'+type+'.template').clone();
    var lastItem = $('.'+type).last();
    newItem.removeClass('template');
    newItem.addClass('instance');
    lastItem.after(newItem);
  })

  $(document).on('click', '.remove-instance', function() {
    var instance = $(this).parents('.instance').first();
    instance.remove();
  })

  $(document).on('click', '.copybox input', function(){
    $(this).select();
  });

  $(document).on('click', '.click-to-reveal', function(){
    var link = this;

    var value = $(link).data('value');
    var label = $(link).data('label');
    var revealed = $(link).hasClass('revealed');

    if (!revealed) {
      $(link).hide();

      var hideTimeout;

      var input = document.createElement('input');

      input.type = 'text';
      input.style.width = '20em';
      input.addEventListener('click', () => {
        input.select();
      });
      input.value = value;
      input.style.marginRight = '1em';

      var hideLink = document.createElement('a');
      hideLink.innerText = 'Hide';
      hideLink.href = 'javascript:;';

      var hide = () => {
        $(link).show();
        input.remove();
        hideLink.remove();
        clearTimeout(hideTimeout);
      }

      $(link).after(input);
      $(input).after(hideLink);

      hideLink.addEventListener('click', () => hide());

      hideTimeout = setTimeout(() => hide(), 30*1000);
    }
  });

  $(document).on('click', '.copybox #copy-to-clipboard', function(){
    $(this).parents('.copybox:first').find('input').select();
    document.execCommand("Copy");
  });

  // Using jquery-ujs in coordination with rails remote form
  //jquery-ujs before handler: Validate dates
  $(document).on("ajax:beforeSend", '#filter-form', (evt, xhr, settings) => {
    $("#submit-timeframe").prop("disabled", true);
    var fromVal = moment($('#filter-form #from').val())
    var toVal = moment($('#filter-form #to').val())
    if(toVal.diff(fromVal) < 0){
        $('#error-alert').css('display', 'block')
        $('#error-alert').html("Invalid date range")
        $("#submit-timeframe").prop("disabled", false);
        $("#results").html("");
        return false
    } else {
      $("#submit-timeframe").prop("disabled", false);
      return true
    }
  })
  //jquery-ujs success handler: appends the html response
  $(document).on("ajax:success", '#filter-form', (event, data, status, xhr) => {
    console.log("data =>", data)
    $( "#results" ).html( data );
    $("#submit-timeframe").prop("disabled", false);
  })
  //jquery-ujs error handler: Shows error message when response has failed
  $(document).on("ajax:error", '#filter-form', (evt, xhr, status, error) => {
    $('#error-alert').css('display', 'block')
    $('#error-alert').html("Error in the request")
    $("#submit-timeframe").prop("disabled", false);
  })

  function setMembershipValue(param) {  }

  $(document).on(
    "change",
    `#offer-set-form .offer .period-select`,
    (event) => {
      let select = $(event.target)

      let productSelect = select
        .closest('.offer')
        .find(`.product-select.period-${select.val()}`)
        .first()
      ;
      let hiddenField = $(`#${productSelect.data("hidden-field-id")}`)

      hiddenField.val(productSelect.val())
    }
  );

  $(document).on(
    "change",
    `#offer-set-form .offer .product-select`,
    (event) => {
      let select = $(event.target)
      let hiddenField = $(`#${select.data("hidden-field-id")}`)
      hiddenField.val(select.val())
    }
  );
}();
