import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "input"];

  connect() {
    if (!this.hasInputTarget || !this.hasContentTarget) return
    this.refresh()
  }

  update(element, checked) {
    element.checked = checked
  }

  toggleContent() {
    let checked = this.inputTarget.checked
    this.contentTargets.forEach(element => {
      this.update(element, checked)
    });
  }

  toggleInput() {
    let checked = this.contentTargets.every(checkbox => checkbox.checked)
    if (this.inputTarget.checked != checked) {
      this.update(this.inputTarget, checked)
    }
  }

  refresh() {
    this.update(this.inputTarget, false)
    this.contentTargets.forEach(element => {
      this.update(element, false)
    });
  }
}