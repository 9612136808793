$(document).on('turbolinks:load', () => {
  $('.user-tabs a').click(function (e) {
    e.preventDefault()
    $(this).tab('show')
    window.history.pushState({ turbolinks: {} }, "", $(this).attr('href'));
  });

  // Go to tab for the location hash
  var tabs = $('.user-tabs a').map(function (index, tab) {
    return tab.hash;
  }).get();
  if (tabs.includes(location.hash)) {
    $('.nav-tabs a[href="' + location.hash + '"]').tab('show');
  }

  $('.native-id').click(function() {
    $(this).select();
  });
  $('.copy-to-clipboard').click(function() {
      $(this).prev().select();
      document.execCommand("Copy");
  });
});