import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  connect() {
    this.initializeDatepickers()
  }

  initializeDatepickers(event) {
    $(this.sourceTargets).datetimepicker({
        todayBtn: 'linked',
        todayHighlight: 'true',
        format: 'yyyy-mm-dd hh:ii'
      });
  }

}
