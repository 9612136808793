import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "output", "sync" ]

  connect() {
    this.addMultipleEventListener(this.inputTarget, 'keyup change blur', () => {
      if (this.syncTarget.checked)
        this.format(this.inputTarget.value);
    });

    this.addMultipleEventListener(this.syncTarget, 'change', () => {
      if (this.syncTarget.checked)
        this.format(this.inputTarget.value);
    });

    this.addMultipleEventListener(this.outputTarget, 'change blur', () => {
      this.format(this.outputTarget.value);
    });
  }

  format(text) {
    this.outputTarget.value = this.slugify(text);
  }

  slugify(text) {
    var slugify = require('slugify');

    return slugify(
      text,
      {
        lower: true,
        strict: true,
        locale: 'en'
      }
    );
  }

  addMultipleEventListener(element, eventNames, listener) {
    var events = eventNames.split(' ');
    events.forEach((event) => {
      element.addEventListener(event, listener, false);
    });
  }
}