import { Controller } from "stimulus"
import showdown from "showdown"
const converter = new showdown.Converter({
  tables: true,
  tasklists: true
});

export default class extends Controller {
  static targets = ["viewer", "input", "viewerToggle", "inputToggle", "editor", "fullscreenToggle", "compactToggle"];

  fullscreenMode(event) {
    event.preventDefault();
    this.fullscreenToggleTarget.classList.add("hidden");
    this.compactToggleTarget.classList.remove("hidden");
    this.editorTarget.classList.add("markdown-editor-fullscreen");
  }

  compactMode(event) {
    event.preventDefault();
    this.compactToggleTarget.classList.add("hidden");
    this.fullscreenToggleTarget.classList.remove("hidden");
    this.editorTarget.classList.remove("markdown-editor-fullscreen");
  }

  showViewer(event) {
    event.preventDefault();
    this.inputTarget.classList.add("hidden");
    this.inputToggleTarget.classList.remove("hidden");
    this.viewerTarget.classList.remove("hidden");
    this.viewerToggleTarget.classList.add("hidden");
    this.viewerTarget.innerHTML = converter.makeHtml(this.inputTarget.value);
  }

  showInput(event) {
    event.preventDefault();
    this.viewerTarget.classList.add("hidden");
    this.viewerToggleTarget.classList.remove("hidden");
    this.inputTarget.classList.remove("hidden");
    this.inputToggleTarget.classList.add("hidden");
  }
}
