import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "primaryButton" ]

  /**
   * submit the form by clicking the primary button
   * ransack's search_form_for form_helper stripped out the stimulus element options
   */
  change() {
    this.primaryButtonTarget.click();
  }
}