import { Controller } from "stimulus"
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = [ "input", "replacement", "filterKind", "kind", "strict" ]
  static values = { url: String }

  connect() {
    this.change = debounce(this.change, 3000)
  }

  change(event) {
    event.preventDefault()

    let url = this.urlValue
      + "?text=" + this.inputTarget.value
      + "&filter_kind=" + this.filterKindTarget.value
      + "&kind=" + this.kindTarget.value
      + "&strict=" + this.strictTarget.value

    fetch(url)
      .then(response => response.text())
      .then(html => this.replacementTarget.innerHTML = html)
  }

}