// Fix select2 form inputs duplication
// https://github.com/pascallaliberte/stimulus-turbolinks-select2
import Turbolinks from 'turbolinks'
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

Turbolinks.start()
