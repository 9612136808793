import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"];

  toggle(event) {
    event.preventDefault();
    if (event.target.checked) {
      this.buttonTarget.disabled = false;
    } else {
      this.buttonTarget.disabled = true;
    }
  }
}
