import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [ "list", "template", "item", "sortable" ]

  connect() {
    if (this.hasSortableTarget) {
      this.Sortable = Sortable.create(this.sortableTarget, {
        handle: ".sortable-handle"
      })
    }

    console.log(`Setting up ${this.itemTargets.length} initial list item indices...`);
    for (let i = 0, max = this.itemTargets.length; i < max; ++i) {
      let element = this.itemTargets[i];
      this.setItemIndex(element, i);
    }
  }

  setItemIndex(element, index) {
    for (let input of Array.from(element.querySelectorAll('input, select, option, textarea'))) {
      if (input.name)
        input.name = input.name.replace(/{{index}}/g, index);
      if (input.value)
        input.value = input.value.replace(/{{index}}/g, index);
      if(input.id){
        input.id = input.id.replace(/{{index}}/g, index);
      }
      if($(input).data("hiddenFieldId")){
        let hiddenFieldId = $(input).data("hiddenFieldId").replace(/{{index}}/g, index);
        $(input).data("hiddenFieldId", hiddenFieldId)
      }
    }
  }

  addItem(event) {
    let list = this.listTarget
    let template = this.templateTarget

    let button = event.target.closest('a, button');

    let templateOverideId = button.getAttribute('data-override-template')
    if (templateOverideId) {
      template = document.getElementById(templateOverideId); //querySelector('#' + templateOverideId)
    }


    let clone = template.content.cloneNode(true)
    clone.firstElementChild.setAttribute('data-target', 'list.item')

    let index = this.itemTargets.length

    this.setItemIndex(clone, index);
    list.appendChild(clone)

    const customEvent = document.createEvent("CustomEvent")
    customEvent.initCustomEvent("listItemAdded", true, true, null)
    this.element.dispatchEvent(customEvent)
  }

  removeItem(event) {
    var indexAttribute = this.data.get("indexAttribute")
    if (!indexAttribute)
      return;

    event.preventDefault()
    event.currentTarget.closest('.list-item').remove()
  }

  markItemDeleted(event) {
    var indexAttribute = this.data.get("indexAttribute")
    if (!indexAttribute)
      return;

    event.preventDefault()
    let itemElement = event.currentTarget.closest('.list-item');

    itemElement.style.display = 'none';
    itemElement.querySelector('#mark_deleted').checked = true;
  }
}