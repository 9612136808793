import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ "partialParent" ]
  static values = {
    channel: String
  }

  initialize() {
  }

  connect() {
    let partialController = this;

    this.subscription = consumer.subscriptions.create(
      {
        channel: partialController.channelValue,
        token: window.MODERATION_AUTH_TOKEN
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
          console.debug('Connected to channel: ', partialController.channelValue);
        },
        disconnected() {
          // Called when the subscription has been terminated by the server
          console.debug('Disconnected from channel.');
        },
        received(data) {
          if (data.action == 'add') {
            partialController.renderPartial(data)
          } else if (data.action == 'remove') {
            partialController.removeElement(data)
          } else if (data.action == 'update') {
            partialController.removeElement(data)
            partialController.renderPartial(data)
          } else {
            console.error('Received unknown data from channel.')
          }
        }
      }
    );
  }

  disconnect() {
    console.debug('Unsubscribed from channel.');
    this.subscription.unsubscribe();
  }

  renderPartial(data) {
    let partialBody = this._parsePartial(data.partial)
    let parent = this.partialParentTarget
    if (parent && partialBody.firstChild) {
      parent.prepend(partialBody.firstChild)
      if (parent.rows.length > 1) {
        let sortedRows = Array.from(parent.rows).sort(
          (a,b) => a.dataset.sortKey == b.dataset.sortKey ? 0 : (a.dataset.sortKey < b.dataset.sortKey ? 1 : -1)
        )
        sortedRows.forEach((row, index) => {
          let refNode = parent.rows[index]
          if (refNode) {
            refNode.parentElement.insertBefore(row, refNode)
          } else {
            parent.prepend(row)
          }
        })
      }
    }
  }

  removeElement(data) {
    console.debug('Removing element, id', data.id)
    document.getElementById(data.id).remove()
  }

  // wrap it in template tags
  _parsePartial(responseHTML){
    let parser = new DOMParser();
    let responseDocument = parser.parseFromString( `<template>${responseHTML}</template>` , 'text/html')
    let parsedHTML = responseDocument.head.firstElementChild.content
    return parsedHTML
  }
}